<template>
  <r-container class="single-doctor">
    <r-row v-if="doc !== null">
      <r-col class="col-12 md-7">
        <r-card class="br-md">
          <r-container>
            <r-row>
              <r-col class="col-2">
                <r-btn class="br-lg color-one-text" outlined>
                  <span>{{ $t("share") }}</span>
                  <r-icon v-html="$r.icons.share_variant"></r-icon>
                </r-btn>
                <r-btn text class="mt-4 color-eye">
                  <r-icon class="ml-1 mr-1" v-html="$r.icons.eye"></r-icon>
                  <span class="pt-1"> {{ doc.views }} </span>
                </r-btn>
              </r-col>
              <r-col class="col-10 text-center">
                <r-avatar :size="120">
                  <r-img
                    :alt="doc.users[0].name"
                    :src="
                      '/' +
                      $helper.ifHas(
                        doc,
                        'storage/img/not_found.jpg',
                        'users',
                        0,
                        'images',
                        0
                      )
                    "
                    width="120"
                    height="120"
                  ></r-img>
                </r-avatar>

                <h1 class="color-one-text display-5 mt-1 font-weight-bold">
                  {{ doc.users[0].name }} {{ doc.users[0].family }}
                </h1>
                <div class="mt-3 display-4 font-weight-bold">
                  {{ doc.specialist.name }}
                </div>
                <r-btn class="btn-menu mt-5 btn-doc" text>
                  <r-icon v-html="$r.icons.timer_sand"></r-icon>
                  <span>ویزیت به صورت آنلاین</span>
                </r-btn>
                <r-col class="col-12 d-flex v-end h-center">
                  <r-rating
                    size="x-small"
                    :model-value="doc.score || 0"
                    readonly
                  ></r-rating>

                  <r-icon
                    v-html="$r.icons.account"
                    class="mr-3 color-one-text"
                  ></r-icon>
                  <span class="color-one-text pr-1 pt-1">
                    {{ doc.total_reviews }}
                  </span>
                </r-col>
              </r-col>
            </r-row>
            <r-divider></r-divider>
            <r-row>
              <r-col class="col-12">
                <div class="subtitle-1 font-weight-bold color-black-text">
                  {{ $t("full_info_doc") }} {{ doc.users[0].name }}
                  {{ doc.users[0].family }}
                </div>
                <div class="br-btm mt-2"></div>
                <div class="mt-2">
                  {{ $t(["education", [doc.education]]) }}
                </div>
                <div class="mt-2">
                  {{ doc.description }}
                </div>
              </r-col>
            </r-row>
          </r-container>
        </r-card>
      </r-col>
      <r-col class="col-12 md-5 col-left">
        <r-card class="card-sticky">
          <r-container>
            <r-row class="pr-3">
              <r-col class="col-12 mt-2 text-icon d-flex v-baseline">
                <r-btn class="btn-menu" icon text>
                  <r-icon v-html="$r.icons.account_supervisor"></r-icon>
                </r-btn>
                <div>نوبت اینترنتی و ویزیت آنلاین</div>
              </r-col>
              <div class="hdiv mr-3"></div>
            </r-row>
            <r-row class="pr-3">
              <r-col class="col-2">
                <r-btn class="btn-menu color-one-text" size="x-large" icon text>
                  <r-icon v-html="$r.icons.calendar_clock"></r-icon>
                </r-btn>
              </r-col>
              <r-col class="col-10 subtitle-1">
                <div>دریافت نوبت آنلاین</div>
                <div>ویزیت به صورت آنلاین</div>
              </r-col>
            </r-row>
            <r-row>
              <r-col class="col-4">
                <r-btn block class="btn-menu" text @click="nextDay(-1)">
                  <r-icon v-html="$r.icons.chevron_left"></r-icon>
                  <span>روز قبل</span>
                </r-btn>
              </r-col>
              <r-col class="col-4 col-date">
                <r-date-picker
                  v-model="date"
                  label="تاریخ"
                  tile
                  :isDateDisabled="checkDate"
                  @update:model-value="get_visits"
                ></r-date-picker>
              </r-col>
              <r-col class="col-4">
                <r-btn block class="btn-menu" text @click="nextDay(1)">
                  <span>روز بعد</span>
                  <r-icon v-html="$r.icons.chevron_right"></r-icon>
                </r-btn>
              </r-col>
            </r-row>
            <r-row :class="{ 'row-loading': loading }" class="pr-3">
              <r-col
                v-if="loading"
                class="col-loading d-flex h-center v-center"
              >
                <r-progress-circular
                  size="100"
                  indeterminate
                  width="4"
                ></r-progress-circular>
              </r-col>
              <r-col
                class="col-12"
                v-for="(visit, place_id) in visits"
                :key="place_id"
              >
                <div class="subtitle-1 d-flex h-space-between">
                  {{ placeName(place_id) }}
                  <span class="color-info br-sm px-2 py-1"
                    >{{ $n(doc["prices"][place_id]) }} {{ $t("toman") }}</span
                  >
                </div>
                <div class="br-btm"></div>
                <div class="subtitle-2">
                  <r-chip
                    selectable
                    class="ma-1"
                    v-for="(t, i) in visit.a"
                    :key="i"
                    @click="newVisit(place_id, t)"
                    :disabled="visit.r.includes(t[0] + '-' + t[1])"
                  >
                    {{ t[0] }}-{{ t[1] }}
                  </r-chip>
                </div>
              </r-col>
              <r-col class="col-12" v-if="$helper.size(visits) === 0">
                <div class="text-center text-icon">
                  نوبتی در این روز تعریف نشده است
                </div>
              </r-col>
              <r-col class="col-12 text-center">
                <r-btn class="btn-menu color-success" text>
                  <span>نوبت خالی بعدی</span>
                  <r-icon v-html="$r.icons.chevron_right"></r-icon>
                </r-btn>
                <div class="mt-3">همین فردا</div>
              </r-col>
            </r-row>
          </r-container>
        </r-card>
      </r-col>
      <r-col class="col-12">
        <div class="subtitle-1 font-weight-bold color-black-text">
          محل های همکار
        </div>
        <div class="br-btm mt-2"></div>
      </r-col>
      <r-col class="col-12 md-6" v-for="(place, i) in doc.places" :key="i">
        <r-card class="pa-1">
          <r-img
            :src="'/' + place.images[0]"
            :alt="place.name"
            :w-p-h="2"
          ></r-img>
          <div class="d-flex v-baseline h-space-between px-1">
            <div class="title">{{ place.name }}</div>
            <div class="color-info-text">{{ $t(place.type) }}</div>
          </div>
          <div class="subtitle-2 mt-2">
            {{ place.address.state }} - {{ place.address.city }} -
            {{ place.address.address }}
          </div>
          <div class="text-center my-2">
            <r-btn
              class="color-info-text"
              outlined
              block
              :to="{
                name: 'singlePlace',
                params: { place_id: place._id },
              }"
              >{{ $t("see_more") }}</r-btn
            >
          </div>
        </r-card>
      </r-col>
      <r-col class="col-12">
        <div class="display-5 mt-12">نظرات کاربران</div>
        <div class="br-btm mt-2"></div>
        <home-review :link="`reviews/home/doctor/${doctor_id}`"></home-review>
      </r-col>
    </r-row>
  </r-container>
</template>
<script>
import homeReview from "../../../../extension/review/view/homeReview";
export default {
  name: "singleDoctor",
  components: { homeReview },
  props: {
    doctor_id: String,
  },
  data() {
    return {
      doc: null,
      date: new Date().toISOString().split("T")[0],
      visits: {},
      loading: false,
    };
  },
  created() {
    this.get();
    this.get_visits();
  },
  methods: {
    checkDate(d) {
      if (!this.doc) {
        return false;
      }
      const days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];

      if (Object.keys(this.doc.timesheets).includes(days[d.getUTCDay()])) {
        return false;
      }
      return true;
    },
    nextDay(n) {
      this.date = new Date(new Date(this.date).getTime() + n * 86400000)
        .toISOString()
        .split("T")[0];
      this.get_visits();
    },
    newVisit(place_id, time) {
      this.loading = true;
      this.$axios
        .post(`user/visits/${this.doctor_id}/${place_id}`, {
          time: time,
          date: this.date,
        })
        .then(
          () => {
            this.loading = false;
            this.get_visits();
          },
          () => {
            this.loading = false;
          }
        );
    },
    placeName(id) {
      if (this.doc !== null) {
        for (let i = 0; i < this.doc.places.length; i++) {
          if (this.doc.places[i]._id === id) {
            return this.doc.places[i].name;
          }
        }
      }

      return id;
    },
    get() {
      this.$axios
        .get("/home/doctors/show/" + this.doctor_id)
        .then(({ data }) => {
          this.doc = data;
        });
    },
    get_visits() {
      this.loading = true;
      this.$axios
        .get(`home/doctors/visits/${this.doctor_id}/${this.date}`)
        .then(
          ({ data }) => {
            this.visits = data;
            this.loading = false;
          },
          () => {
            this.visits = {};
            this.loading = false;
          }
        );
    },
  },
};
</script>
<style lang="scss">
.single-doctor {
  .row-loading {
    position: relative;
    .col-loading {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: rgba(10, 140, 100, 0.4);
      font-size: 20px;
      color: white;
    }
  }
  .br-btm {
    &:after {
      content: "";
      width: 50px;
      border-bottom: 2px solid #f5a130;
      display: block;
    }
  }

  .col-left {
    position: relative;
  }

  .card-sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 70px;
  }

  .text-icon {
    font-size: 14px;
    font-weight: 500;
  }

  .hdiv {
    width: 100px;
    height: 2px;
    border-radius: 10px;
    background-color: #f5a130;
  }

  .btn-doc {
    padding: 10px;
    border: 1px solid #bed1ec;
    border-radius: 10px;
  }

  .color-eye {
    color: #6e716e;
  }

  .col-date {
    margin-top: -18px;
  }
}
</style>
